import { useEffect, useMemo, useState } from "react";
import { orderBy } from "lodash";
import SectionBox from "../SectionBox";
import Meter from '../Meter';
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import Table from "../Table";
import Title from "../Title";
import styles from './styles.module.css';
import { useNavigate, useParams } from "react-router-dom";
import API, { ApiEquipmentDetails } from "../../api";
import { Centered, ErrorMsg, Loader } from "../Sugar";
import { createNoticeMarker, requestedTimeFormat } from "../../util";
import { IPump, IAlert, INoticeMarker, EquipmentType } from "../../types";
import Plot, { plotColors } from "../Plot";
import { LayoutAxis } from "plotly.js";
import RecentAlerts from "../RecentAlerts";
import { useAlerts } from "../../hooks";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { mostSevereAlertStatus } from "../Alerts";

interface StatusProps {
  equipmentId: string,
  statusNotices: IAlert[]
}

interface OperationProps {
  pump: IPump,
  equipmentId?: string
}

interface OverviewTableProps {
  pump: IPump
}

interface IEquipmentSeriesResp {
  from: string,
  to: string,
  series: {
    physical_quantity: string,
    nominalValue: number | null,
    maxValue: number | null,
    times: Array<string>
    values: Array<number | null>
  }[],
}

const SERIES_ORDER: Record<string, number> = {
  'phase_current': 0,
  'mechanical_power': 1,
  'rotation_speed': 2,
  'torque': 3,
  'power_factor': 4,
  'efficiency': 5,
}

const Status = ({ equipmentId, statusNotices }: StatusProps) => {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [chartData, setChartData] = useState<IEquipmentSeriesResp | null>(null);
  const [refreshIteration, setRefreshIteration] = useState<number>(0);
  const [timeRange, setTimeRange] = useState<string[]>(['now-24h', 'now']);
  const [noticeMarker, setNoticeMarker] = useState<INoticeMarker | null>(null);

  const isAutoRefreshOn = timeRange[0].includes('now') || timeRange[1].includes('now');

  const addNoticeMarker = (notice: IAlert) => {
    const { timerangeStart, timerangeEnd, noticeTitle, noticeStart } = createNoticeMarker(notice);
    setTimeRange([timerangeStart, timerangeEnd]);
    setNoticeMarker({ title: noticeTitle, startTime: noticeStart });
  }

  const removeNoticeMarker = () => {
    setNoticeMarker(null);
  }

  useEffect(() => {
    let isMounted = true;
    let maybeUpdateTimer: ReturnType<typeof setTimeout> | null = null;

    const fetchSeries = () => {
      const params = new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1])
      }).toString()

      API.getEquipmentSeries(equipmentId, params).then((data) => {
        if (isMounted && data.series.length !== 0) {
          setIsInitialLoading(false);
          setChartData(data)
          if (isAutoRefreshOn) {
            maybeUpdateTimer = setTimeout(() => {
              setRefreshIteration(refreshIteration + 1);
            }, 5000);
          }
        }
      }).catch(() => {
        setIsInitialLoading(false);
        setErrorMsg(t('error_history_data'));
      });
    }

    fetchSeries();

    // cleanup
    return () => {
      isMounted = false;
      if (maybeUpdateTimer !== null) {
        clearTimeout(maybeUpdateTimer);
      }
    };
  }, [equipmentId, timeRange, refreshIteration, isAutoRefreshOn, t]);

  const statusMeters = () => {
    if (isInitialLoading) {
      return (
        <Loader />
      );
    }
    // Construct meters for all series returned...
    if (chartData) {
      const sortedSeries = orderBy(chartData.series, (serie) => SERIES_ORDER[serie.physical_quantity]);
      const meterData = sortedSeries.flatMap((serie) => {
        if (serie.values.length === 0) {
          return [];
        }
        const lastValue = serie.values[serie.values.length - 1];
        if (lastValue === null) {
          return [{
            quantity: serie.physical_quantity,
            value: 0,
            percent: 0,
          }];
        }
        // If we don't know the maxValue for scaling, display the meter at 0% but still show value.
        const percent = serie.maxValue !== null ? lastValue / serie.maxValue * 100 : 0;
        return [{
          quantity: serie.physical_quantity,
          value: lastValue,
          percent: Math.min(100, percent),
        }];
      });

      return (
        <div className={styles.statusMeters}>
          { meterData.map((m: any) => (
            <Meter key={m.quantity} progress={m.percent} value={m.value} color={'green'} title={t(m.quantity)} />
          )) }
        </div>
      )
    }

    return (
      <Centered>
        <ErrorMsg message={errorMsg} />
      </Centered>
    );
  }

  const statusPlot = () => {
    if (isInitialLoading) {
      return (
        <Loader text={t('loading_history_data')} />
      );
    }
    // Keep track of the maximum phase current value seen to scale the plot y-range.
    let maxPhaseCurrent = 0.0;
    if (chartData) {
      const sortedSeries = orderBy(chartData.series, (serie) => SERIES_ORDER[serie.physical_quantity])
      const plotlyDataArray = sortedSeries.flatMap((serie) => {
        if (serie.physical_quantity === 'phase_current') {
          maxPhaseCurrent = Math.max(maxPhaseCurrent, serie.nominalValue || 0.0);
          return [{
            x: serie.times.map(t => (new Date(t))),
            y: serie.values.map(v => {
              if (v !== null) {
                maxPhaseCurrent = Math.max(maxPhaseCurrent, v);
              }
              return v;
            }),
            yaxis: 'y1',
            yhoverformat: '.1f',
            mode: 'lines',
            line: {
              shape: 'hv', // stairstep
              color: plotColors[SERIES_ORDER[serie.physical_quantity]]
            },
            name: t(serie.physical_quantity),
            connectgaps: false,
          }];
        } else if (serie.nominalValue === null) {
          // If nominalValue is not set, skip the series since we can't scale it.
          return [];
        } else {
          // Other series than phase current: Scale to fraction of nominal value and display on same
          // axis.
          const nominalValue = serie.nominalValue;
          return [{
            x: serie.times.map(t => (new Date(t))),
            y: serie.values.map(v => {
              return v !== null ? 100 * v / nominalValue : null;
            }),
            customdata: serie.values,
            yaxis: 'y2',
            yhoverformat: '.1f',
            hovertemplate: '%{customdata:.1f}',
            mode: 'lines',
            line: {
              shape: 'hv', // stairstep
              color: plotColors[SERIES_ORDER[serie.physical_quantity]]
            },
            name: t(serie.physical_quantity),
            connectgaps: false,
          }];
        }
      });

      const yMax = Math.max(2.0, 1.2 * maxPhaseCurrent);

      return (
        <Plot
          data={plotlyDataArray}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          removeNoticeMarker={removeNoticeMarker}
          noticeMarker={noticeMarker}
          showTimerangePicker
          layout={{
            legend: {
              y: -0.2
            },
            xaxis: {
              type: 'date',
              showgrid: false,
              color: 'white',
              domain: [0.02, 1]  // Magic margin...
            }, 
            yaxis: {
              title: `${t('phase_current')} (A)`,
              range: [0.0, yMax],
              color: 'white',
              visible: true,
            },
            yaxis2: {
              title: `${t('percent_of_nominal_value')} (%)`,
              rangemode: 'tozero',
              color: 'white',
              overlaying: 'y',
              side: 'right',
              showgrid: false,
            },
          }}
        />
      );
    }
    // Error loading
    return (
      <Centered>
        <ErrorMsg message={errorMsg} />
      </Centered>
    );
  }

  return (
    <>
    { isInitialLoading || (chartData && chartData.series.length > 0) ?
      <>
        <SectionBox title={t('status')} layoutArea={'status-meters'} status={mostSevereAlertStatus(statusNotices)} noOverflow>
          { statusMeters() }
        </SectionBox>

        { statusNotices.length > 0 && (
          <SectionBox title={`${t('alerts')}`} layoutArea={'status-notice'} noOverflow>
            <RecentAlerts
              alertHistory={statusNotices}
              showAlerting={statusNotices.length > 0}
              onJumpToAlert={addNoticeMarker}
            />
          </SectionBox>
        )}

        <SectionBox layoutArea={'status-plot'} noOverflow>
          { statusPlot() }
        </SectionBox>
      </>
    :
      <SectionBox title={t('status')} layoutArea={'status-meters'}>
        <Centered>
          <ErrorMsg message={t('no_data_available')} />
        </Centered>
      </SectionBox>
    }
  </>
  )
}

const Operation = ({ pump, equipmentId }: OperationProps) => {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>(null);
  const [refreshIteration, setRefreshIteration] = useState<number>(0);
  const timeRange = useMemo(() => ['now-24h', 'now'], []);

  const isAutoRefreshOn = timeRange[0].includes('now') || timeRange[1].includes('now');

  useEffect(() => {
    let isMounted = true;
    if (equipmentId) {
      let maybeUpdateTimer: ReturnType<typeof setTimeout> | null = null;

    const fetchOperationData = () => {
      const params = new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1])
      }).toString()

        API.getPumpOperatingPoint(equipmentId, params).then((data: any) => {
          if (isMounted) {
            if (!('error' in data)) {
              setChartData(data);
              if (isAutoRefreshOn) {
                maybeUpdateTimer = setTimeout(() => {
                  setRefreshIteration(refreshIteration + 1);
                }, 5000);
              }
            }
            setIsInitialLoading(false);
          }
        }).catch(() => {
          setIsInitialLoading(false);
        })
      }
      
      fetchOperationData();

      return () => {
        isMounted = false;
        if (maybeUpdateTimer !== null) {
          clearTimeout(maybeUpdateTimer);
        }
      };
    }
  }, [refreshIteration, isAutoRefreshOn, equipmentId, timeRange])

  const powerFlowPlot = () => {

    if (isInitialLoading) {
      return (
        <Loader text={t('loading_history_data')} />
      );
    }

    if (chartData !== null) {
      const { nominalHead, nominalPower, nominalFlowRate } = pump;
      const { powers, flowRates, heads, lastFlowRate, lastHead, lastPower } = chartData;

      const operatingPoint = {
        name: t('operating_point'),
        yhoverformat: '.2f',
        mode: 'markers',
        marker: {
          color: '#0093E5'
        },
      }

      const nominalPoint = {
        name: t('nominal_point'),
        yhoverformat: '.2f',
        mode: 'markers',
        marker: {
          color: 'transparent',
          size: 20,
          line: {
            color: '#EFB600',
            width: 3
          }
        }
      }

      const lastOperatingPoint = {
        name: t('last_operating_point'),
        yhoverformat: '.2f',
        mode: 'markers',
        marker: {
          color: 'transparent',
          size: 10,
          line: {
            color: '#0033FF',
            width: 3
          }
        }
      }

      const plotlyFlowRatePowerArray = [
        {
          x: flowRates,
          y: powers,
          ...operatingPoint,
        },
        {
          x: [lastFlowRate],
          y: [lastPower],
          ...lastOperatingPoint,
        },
        {
          x: [nominalFlowRate],
          y: [nominalPower],
          ...nominalPoint,
        },
      ]

      const plotlyFlowRateHeadArray = [
        {
          x: flowRates,
          y: heads,
          ...operatingPoint,
        },
        {
          x: [lastFlowRate],
          y: [lastHead],
          ...lastOperatingPoint,
        },
        {
          x: [nominalFlowRate],
          y: [nominalHead],
          ...nominalPoint,
        },
      ]

      const layoutAxis = (rangeTo: number) => {
        const axis: Partial<LayoutAxis> = {
          range: [0, rangeTo],
          color: 'white',
          rangemode: 'tozero',
        };

        return axis;
      }

      return (
        <>
          <Plot
            data={plotlyFlowRatePowerArray}
            xAnnotation='(m³/h)'
            yAnnotation='(kW)'
            layout={{
              title: `${t('qp_graph')}: ${t('shaft_power')} (kW) / ${t('flow')} (m³/h)`,
              xaxis: layoutAxis((nominalFlowRate || 0) + 10),
              yaxis: layoutAxis((nominalPower || 0 ) + 10),
            }}
          />

          <Plot
            data={plotlyFlowRateHeadArray}
            xAnnotation='(m³/h)'
            yAnnotation='(m)'
            layout={{
              title: `${t('qh_graph')}: ${t('head')} (m) / ${t('flow')} (m³/h)`,
              xaxis: layoutAxis((nominalFlowRate || 0) + 10),
              yaxis: layoutAxis((nominalHead || 0 ) + 10),
            }}
          />
        </>
      );
    }
    return (
      <Centered>
        <ErrorMsg message={t('no_data_available')} />
      </Centered>
    );
  }

  return (
    <SectionBox layoutArea='operation' title={t('operation_point')}>
      <div>
        { powerFlowPlot() }
      </div>
    </SectionBox>
  )
}

const OverviewTable = ({ pump }: OverviewTableProps) => {
  const { t } = useTranslation();
  const tableColumns: [keyof IPump, string][] = [
    ['modelName', t('model')],
    ['nominalVoltage', `${t('voltage')} (V)`],
    ['nominalCurrent', `${t('current')} (A)`],
    ['nominalPowerFactor', 'cos φ'],
    ['nominalPower', `${t('power')} (kW)`],
    ['nominalSpeed', 'RPM'],
  ];
  const formatters: Record<string, any> = {
    'nominalVoltage': (val: number) => `${val.toFixed(1)} V`,
    'nominalCurrent': (val: number) => `${val.toFixed(1)} A`,
    'nominalPower': (val: number) => `${val.toFixed(1)} kW`,
    'nominalSpeed': (val: number) => `${val.toFixed(1)} r/min`,
  }
  const pumpRows: JSX.Element[] = [];
  if (pump) {
    for (const [key, label] of tableColumns) {
      const pumpColumns: JSX.Element[] = [];
        const formatter = formatters[key];
        const val = pump[key] ? (formatter ? formatter(pump[key]) : pump[key]) : '-';
        pumpColumns.push(
          <td key={label}>{ val }</td>
        );
      
      pumpRows.push(
        <tr key={ label }>
          <td>{ label }</td>
          { pumpColumns }
        </tr>
      );
    }
  }
  return (
    <div className={styles.overviewTable}>
      <Table rows={pumpRows}/>
    </div>
  )
}


const Pump = () => {
  const { t } = useTranslation();
  const { alerts, loadingAlerts } = useAlerts();
  const params = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [equipmentInfo, setEquipmentInfo] = useState<ApiEquipmentDetails | null>(null);

  const equipmentId = params.publicId as string;

  useEffect(() => {
    let isMounted = true;
    if (equipmentId) {
      API.getEquipmentDetailsById(equipmentId).then(data => {
        if (isMounted) {
          setEquipmentInfo(data);
        }
      }).catch(() => {
        navigate('/fleet');
      }).finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      })
    }
    return () => {
      isMounted = false;
    };
  }, [equipmentId, navigate]);

  const [pump, equipmentType] = useMemo(() => {
    let pump: IPump | null = null;
    let equipmentType: EquipmentType | null = null;
    if (equipmentInfo !== null) {
      const eq = equipmentInfo.equipment;
      if ('pump' in eq || 'motor' in eq) {
        equipmentType = 'pump' in eq ? 'motor+pump' : 'motor';
        pump = {
          publicId: eq.publicId,
          name: eq.name,
          modelName: (eq.pump && eq.pump.modelName) || (eq.motor && eq.motor.modelName) || '-',
          nominalVoltage: (eq.motor && eq.motor.nominalVoltageVolts) || null,
          nominalCurrent: (eq.motor && eq.motor.nominalCurrentAmperes) || null,
          nominalPower: (eq.pump && eq.pump.nominalPower) || (eq.motor && eq.motor.nominalPower) || null,
          nominalPowerFactor: (eq.motor && eq.motor.nominalPowerFactor) || null,
          nominalSpeed: (eq.motor && eq.motor.nominalSpeedRpm) || null,
          nominalHead: (eq.pump && eq.pump.nominalHeadM) || null,
          nominalFlowRate: (eq.pump && eq.pump.nominalFlowRate) || null,
        };
      }
    }
    return [pump, equipmentType];
  }, [equipmentInfo])

  const [statusNoticesEquipment] = useMemo(() => {
    let statusNotices: IAlert[] = [];
    if (alerts && !loadingAlerts) {
      statusNotices = alerts.filter(alert => alert.forEquipmentPublicId === equipmentId);
    }
    return [statusNotices];
  }, [equipmentId, alerts, loadingAlerts])

  let breadcrumbs: any = [];
  let leftArrow: string = '';
  if (equipmentInfo && pump) {
    if (equipmentInfo.partOfFleets.length < 2) {
      breadcrumbs = [
        {fleetId: equipmentInfo.partOfFleets[0].publicId, to: '/fleet', title: equipmentInfo.partOfFleets[0].name},
        {title: pump.name}
      ];
      leftArrow = 'fleet';
    } else {
      breadcrumbs = [
        {fleetId: equipmentInfo.partOfFleets[0].publicId, to: '/fleet', title: equipmentInfo.partOfFleets[0].name},
        {to: `/station/${equipmentInfo.partOfFleets[1].publicId}`, title: equipmentInfo.partOfFleets[1].name},
        {title: pump.name}
      ];
      leftArrow = `/station/${equipmentInfo.partOfFleets[1].publicId}`
    }
  }

  useEffect(() => {
    if (equipmentInfo !== null && pump !== null) {
      const fleetNames = equipmentInfo.partOfFleets.map((item: any) => `${item.name} - `).join('');
      setTitle(fleetNames + pump.name);
    }
  }, [equipmentInfo, pump]);

  if (loading) {
    return (
      <Loader text={t('loading_pump')} />
    );
  }

  const alertStatus = mostSevereAlertStatus(statusNoticesEquipment);

  return (
    <>
      { title !== null && <Title title={title} /> }
      { pump &&
        <>
          <Breadcrumbs leftArrowTo={leftArrow} links={breadcrumbs} status={alertStatus} />
          <div className={classNames(styles.pumpPageLayout)}>
            <div style={{gridArea: 'overview'}}>
              <SectionBox title={equipmentType === 'motor' ? t('motor_overview') : t('pump_overview')}>
                <OverviewTable pump={pump} />
              </SectionBox>
              <Button text={t('view_pictures')} marginTop onClick={() => console.log(123)} />
              <Button
                text={(equipmentType === 'motor' ? t('reconfigure_motor') : t('reconfigure_pump'))}
                marginTop textColor={'green'} onClick={() => console.log(123)}
              />
            </div>
            <Status equipmentId={equipmentId} statusNotices={statusNoticesEquipment} />
            { equipmentType === 'motor+pump' && <Operation pump={pump} equipmentId={equipmentId} /> }
          </div>
        </>
      }
    </>
  )
}

export default Pump;